.about .aboutme {
  margin-bottom: 60px;
}
.about .info_image img {
  box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  border-radius: 5px;
}

.about .basic_info {
  width: auto;
}
.about .basic_info ul {
  padding: 0;
}
.about .basic_info ul li {
  display: flex;
}
.about .basic_info ul li span {
  width: 35%;
  font-weight: 500;
  color: var(--clr-primary-6);
  text-transform: capitalize;
}
.about .basic_info ul li p {
  width: 65%;
}
.about .skills ul {
  padding: 0;
}
.about .skills ul li {
  display: flex;
}
.about .skills ul li:not(:first-child) {
  padding-top: 20px;
}
.about .skills ul li span {
  width: 90%;
  font-weight: 500;
  color: var(--clr-primary-6);
  text-transform: capitalize;
}
.about .skills ul li p {
  width: 10%;
}

@media (max-width: 992px) {
  .about .basic_info {
    margin: 25px 0
  }
}