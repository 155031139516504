.testimonials {
    background-color: var(--clr-bg-sec);
}
.testimonials h2 {
    text-align: center;
}
.testimonials p {
    text-align: center;
    margin: 0;
}
.testimonials .sliderContainer {
    width: 80%;
    margin: auto;
    text-align: center;
}
.testimonials .review {
    background-color: var(--clr-bg-card);
    margin: 15px auto;
    text-align: center;
    padding: 30px;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    width: 80%;
}
.testimonials .review .quote {
    font-size: 50px;
    color: var(--clr-primary-1);
}
.testimonials .review p {
    font-size: 19px;
    margin: 10px auto 20px;
    width: 90%;
}
.testimonials .review h5 {
    font-weight: 700;
}

@media (max-width: 991px) {
    .testimonials .sliderContainer {
        width: 100%;
    }
    .testimonials .review p {
        width: 100%;
    }
}