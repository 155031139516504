.resume {
  background-color: var(--clr-bg-sec);
}
.resume h2 {
  text-align: center;
}
.resume .res_card {
  background-color: var(--clr-bg-card);
  padding: 30px;
  margin-top: 30px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}

.resume .res_card h4 {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.resume .res_card p {margin: 0;}
.resume .res_card p span {
    color: var(--clr-primary-1);
    font-weight: 600;
}