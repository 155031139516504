.slick-arrow {
    display: none !important;
}
.slick-slide {
    padding: 10px 20px;
}
.slick-dots li button:before {
    color: var(--clr-primary-1);
    opacity: .4;
    font-size: 10px;
}
.slick-dots li.slick-active button:before {
    color: var(--clr-primary-1);
    opacity: 1;
}