.header {
  background-color: var(--clr-bg-sec);
}
.header .header_content {
  padding-top: 10vh;
}
.header .header_content h1 {
  font-weight: 700;
}
.header .header_content a {
  color: var(--clr-primary-5);
  font-weight: 500;
}

@media (max-width: 767px) {
  .header .header_content h1 {
    margin-bottom: 15px;
  }
  .header .header_content {
      padding-top: 1vh;
  }
}
