.footer {
  position: relative;
  background: url('../../../util/images/footer_img.jpg') center center no-repeat;
  background-size: cover;
  overflow: hidden;
  color: #fff;
  text-align: center;
  padding-bottom: 0 !important;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 14, 39, 0.9);
  z-index: 1;
}

.footer .footer_content {
  position: relative;
  z-index: 2;
}
.footer .footer_content h2 {
  color: #fff;
  font-size: 70px;
  font-family: 'Russo One', sans-serif;
  margin-bottom: 20px;
}
.footer .footer_content h2 span {
  color: var(--clr-primary-1);
}
.footer .footer_content a {
  font-size: 20px;
  line-height: 29px;
  display: inline-block;
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  transition: var(--main-transition);
  border-radius: 50%;
  padding: 10px;
  margin: 0 15px 15px;
}
.footer .footer_content a:hover {
  color: var(--clr-primary-1);
}
.footer .footer_content p {
  color: #fff;
  font-size: 14px;
  padding: 10px 0;
}
.footer .footer_content p span {
  color: var(--clr-primary-1);
}
