/*========================================
Author       : Seif Ahmed
Version      : 1.0
==========================================*/

/*
=============== 
Variables
===============
*/
:root {
  /* Primary/main colors */
  --clr-primary-1: #27e278;
  --clr-primary-2: #31aff5;
  --clr-primary-3: #292b2c; /* headings */
  --clr-primary-4: #ffffff; /* links */
  --clr-primary-5: #666666; /* paragraphs */
  --clr-primary-6: #333333; /* span */
  --nav-mode-out: #000; /* nav */
  /* Background/colors */
  --clr-bg: #ffffff;
  --clr-bg-nav: #080a20;
  --clr-bg-sec: #f6f7fd;
  --clr-bg-card: #ffffff;
  /* Transition*/
  --main-transition: all 0.4s ease-in-out;
  /* Shadows */
  --box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}

.dark-theme {
  --clr-bg: #121833;
  --clr-bg-sec: #0c0e27;
  --clr-bg-card: #25263D;
  --clr-primary-3: #ffffff; /* headings */
  --clr-primary-4: #ffffff; /* links */
  --clr-primary-5: #ffffff; /* paragraphs */
  --clr-primary-6: #ffffff; /* span */
  --nav-mode-out: #ffffff; /* nav */
}
.light-theme {
  --clr-bg: #ffffff;
  --clr-bg-sec: #f6f7fd;
  --clr-bg-card: #ffffff;
  --clr-primary-3: #292b2c; /* headings */
  --clr-primary-4: #ffffff; /* links */
  --clr-primary-5: #666666; /* paragraphs */
  --clr-primary-6: #333333; /* span */
  --nav-mode-out: #000; /* nav */
}

/*
--clr-bg: #121833;
--clr-bg-sec: #0C0E27;
--clr-bg-card: #0C0E27; 
*/

/*=========== Start Global =========== */
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--clr-primary-3);
  font-family: 'Montserrat', sans-serif;
}
p {
  color: var(--clr-primary-5);
}
body {
  background-color: var(--clr-bg);
  font-family: 'Roboto', sans-serif;
}
/*=========== End Global =========== */

/* Global Classes */
.sec_heading {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 30px;
}
.pr_heading {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.btn-pr {
  text-align: center;
  text-transform: uppercase;
  margin: 40px auto 0;
  color: #fff;
  background-color: var(--clr-primary-1);
  padding: 10px 0;
  max-width: 200px;
  display: block;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 20px;
  border: 2px solid var(--clr-primary-1);
  transition: var(--main-transition);
}
.btn-pr:hover {
  background-color: #fff;
  color: var(--clr-primary-1);
}
.sec_style {
  padding: 90px 0;
}
.progress {
  height: 0.4rem !important;
}
.progress-bar {
  background-color: var(--clr-primary-1);
}
.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}
/* Global Classes */
