.contact h2 {
    text-align: center;
}
.contact .contact_method {
    padding: 20px 30px;
    box-shadow: var(--box-shadow);
    text-align: center;
    border-radius: 10px;
    margin-top: 15px;
    background-color: var(--clr-bg-card);
}
.contact .contact_method:hover .icon {
    transform: rotate(360deg);
}

.contact .contact_method .icon {
    color: #fff;
    font-size: 40px;
    background-color: var(--clr-primary-1);
    display: inline-block;
    padding: 4px 15px;
    border-radius: 50%;
    transition: var(--main-transition);
}
.contact .contact_method h5 {
    text-transform: uppercase;
    font-weight: 600;
    margin: 20px 0;
}