.portfolio h2 {
  text-align: center;
}
.portfolio .content {
  position: relative;
  width: 100%;
  margin: 15px auto;
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  background-color: #fff;
  -webkit-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  transition: var(--main-transition);
}
.portfolio .content .content_overlay {
  background: var(--clr-primary-1);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
  -webkit-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  transition: var(--main-transition);
}
.portfolio .content:hover .content_overlay {
  opacity: 1;
}
.portfolio .content_image {
  width: 100%;
  height: 250px;
}

.content_details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  transition: var(--main-transition);
}
.portfolio .content:hover .content_details {
  top: 50%;
  left: 50%;
  opacity: 1;
}
.portfolio .content_details h3 {
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.portfolio .content_details p {
  color: #fff;
  font-size: 1em;
  margin-bottom: 1em;
}
.portfolio .content_details span {
  color: var(--clr-primary-1);
  font-weight: 600;
  border: 2px solid #fff;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 20px;
  background-color: #fff;
}
