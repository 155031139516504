/*==========
Navbar
=========== */

.navbar {
  /* padding-top: 1rem !important;
  padding-bottom: 1rem !important; */
  transition: var(--main-transition)
}
.nav_scroll {
  background-color: var(--clr-bg-nav);
  transition: var(--main-transition);
}

.navbar-dark .navbar-nav .nav-link {

  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding-left: .75rem !important;
  padding-right: .75rem !important;
  transition: var(--main-transition) !important;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: var(--clr-primary-1) !important;
}

.navbar .mode_toggler {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  line-height: 0;
  padding: 0 .75rem;
}

@media (max-width: 991px) {
  .navbar .mode_toggler {
    display: block;
    text-align: left;
  }
}
.navlink_in {
  color: var(--clr-primary-4) !important;
}
.navlink_out {
  color: var(--nav-mode-out) !important;
}
.navbar .navbar-brand {
  font-size: 32px;
  font-family: 'Russo One', sans-serif;
}
.navbar .navbar-brand span {
  color: var(--clr-primary-1);
}