/* Header */
.header {
  position: relative;
  background: url('../../../util/images/banner_img.jpg') center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  overflow: hidden;
}

/* Header Overlay */
.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 14, 39, 0.9);
  z-index: 1;
}

.header .header_content {
  position: relative;
  z-index: 2;
  padding-top: 25vh;
}

.header .header_content .banner_content h2 {
  font-size: 70px;
  font-weight: 700;
  line-height: 84px;
  color: #fff;
}
.header .header_content .banner_content h4 {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  margin: 25px 0;
}
.header .header_content .banner_content h4 span {
  color: var(--clr-primary-1);
}
.header .header_content .banner_content h4 span:last-child {
  color: #fff;
}
.header .header_content .banner_content p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.header .header_content .image_banner {
  position: relative;
  text-align: center;
}
.header .header_content .image_banner img {
  border: 10px solid #fff;
  border-radius: 100%;
  max-width: 350px;
  width: 100%;
}

/* Circle Bg */
.circle_bg1 {
  border-radius: 100%;
  height: 650px;
  width: 650px;
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}
.circle_bg1 span {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-name: ripple1;
  -moz-animation-name: ripple1;
  -o-animation-name: ripple1;
  animation-name: ripple1;
  -webkit-animation-duration: 4s;
  -moz-animation-duration: 4s;
  -o-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5s;
  -o-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  background-color: #fff;
}
.circle_bg1 span:nth-child(2) {
  -webkit-animation-delay: 4.5s;
  -moz-animation-delay: 4.5s;
  -o-animation-delay: 4.5s;
  animation-delay: 4.5s;
}
.circle_bg1 span:nth-child(3) {
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
}
.circle_bg1 span:nth-child(4) {
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
  -o-animation-delay: 3.5s;
  animation-delay: 3.5s;
}
.circle_bg1 span:nth-child(5) {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;
}
.circle_bg1 span:nth-child(6) {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.circle_bg1 span:nth-child(7) {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}
.circle_bg1 span:nth-child(8) {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@-webkit-keyframes ripple1 {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.01);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@-moz-keyframes ripple1 {
  0% {
    -moz-transform: scale(0.1);
    transform: scale(0.01);
    opacity: 1;
  }
  50% {
    -moz-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@-o-keyframes ripple1 {
  0% {
    -o-transform: scale(0.1);
    transform: scale(0.01);
    opacity: 1;
  }
  50% {
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes ripple1 {
  0% {
    transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/* Header Media */
@media (max-width: 500px) {
  .circle_bg1 {
    height: 460px;
    width: 460px;
  }
  .header .header_content .image_banner {
    margin-bottom: 60px;
  }
  .header .header_content .banner_content {
    text-align: center;
  }
  .header .header_content .banner_content h2 {
    font-size: 65px;
    line-height: 74px;
  }
}
@media (max-width: 400px) {
  .header .header_content {
    padding-top: 20vh;
  }
  .header .header_content .banner_content h2 {
    font-size: 50px;
    line-height: 60px;
  }
  .header .header_content .banner_content h4 {
    font-size: 20px;
  }
}
@media (min-width: 501px) and (max-width: 1199px) {
  .circle_bg1 {
    height: 500px;
    width: 500px;
  }
  .header .header_content .image_banner {
    margin-bottom: 60px;
  }
  .header .header_content .banner_content {
    text-align: center;
  }
  .header .header_content .banner_content h2 {
    font-size: 65px;
    line-height: 74px;
  }
}
