.footer {
  text-align: center;
  padding-bottom: 0 !important;
  background-color: var(--clr-bg-sec);
}
.footer .footer_content h2 {
  font-size: 70px;
  font-family: 'Russo One', sans-serif;
  margin-bottom: 20px;
}
.footer .footer_content h2 span {
  color: var(--clr-primary-1);
}
.footer .footer_content a {
  font-size: 20px;
  line-height: 5px;
  display: inline-block;
  width: 38px;
  height: 38px;
  padding: 10px;
  margin: 0 12px 15px;
  color: #fff;
}
.footer .footer_content a:first-of-type {
  background-color: #4267b2;
}
.footer .footer_content a:nth-of-type(2) {
  background-color: #bd2c00;
}
.footer .footer_content a:nth-of-type(3) {
  background-color: #0077b5;
}
.footer .footer_content p {
  font-size: 14px;
  padding: 10px 0;
  margin: 0;
}
.footer .footer_content p span {
  color: var(--clr-primary-1);
}
